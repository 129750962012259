import './ValueCell.css';

export default function ValueCell(props){
    const {rightMargin, bottomMargin, value, index, backgroundColor, onClick,cellSize, isMobile} = props; 
    const style = {
                    height: `${cellSize}px`,
                    width: `${cellSize}px`,
                    lineHeight: `${cellSize}px`,
                    marginRight: rightMargin ? "2px" : undefined, 
                    marginBottom: bottomMargin ? "2px": undefined,
                    backgroundColor: backgroundColor,
                    fontSize: isMobile? "32px" : undefined
  };
    return <div className="value-cell" onClick={()=>onClick(index)} style={style}>{value}</div>
}