import './HorizontalApp.css';
import Helmet from "react-helmet"
import {SudokuGrid} from "./Sudoku"
import Grid from './Grid'
import NumberGrid from './NumberGrid';
import StageList from './StageList';
import {useState} from 'react'
function HorizontalApp() {
  const [isGridEditable, setIsGridEditable] = useState(true);
  const [size, setSize] = useState(9);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [values, setValues] = useState(new Array(size*size).fill(""));
  const [candidates, setCandidates] = useState(new Array(size*size).fill(null));
  const [changedCells, setChangeCells] = useState(new Array(size*size).fill(false));
  const [targetAvSet, setTargetAvSet] = useState(new Array(size*size).fill(null));
  const [rootAvSet, setRootAvSet] = useState(new Array(size*size).fill(null));
  const [stageNameList, setStageNameList] = useState([]);
  const [stageGridList, setStageGridList] = useState([]);

  
  const onInputClick = (number) =>{
    changeValue(selectedIndex, number)
}

  const onReset = () =>{
    setSize(9);
    setIsGridEditable(true);
    setSelectedIndex(-1);
    setValues(new Array(size*size).fill(""));
    setCandidates(new Array(size*size).fill(null));
    setChangeCells(new Array(size*size).fill(false));
    setRootAvSet(new Array(size*size).fill(null));
    setTargetAvSet(new Array(size*size).fill(null));
    setStageGridList([]);
  }
  const onSolve = () => {
    setIsGridEditable(false);
    onSolveClick(toMatrix(values, size), setStageNameList, setStageGridList);
    setSelectedIndex(-1);
  }
  const onCellClick = (index) => {
    if(isGridEditable){
      setSelectedIndex(index);
    }   
    }
  const changeValue = (index, newValue) =>{
    const newArray = [...values];
    newArray[index] = newValue;
    setValues([...newArray]);
  }

  const onStageClick= (grid)=>{
    const currentValues = [].concat(...grid.map((row)=>{return row.map((cell)=>{return toValueString(cell.value)})}));
    const currentCandidates = [].concat(...grid.map((row)=>{return row.map((cell)=>{return cell.avSet})}))
    const currentChangedCells = [].concat(...grid.map((row)=>{return row.map((cell)=>{return cell.changed})}))
    const currentTargetAvSet = [].concat(...grid.map((row)=>{return row.map((cell)=>{return cell.changedAvSet})}))
    const currentRootAvSet = [].concat(...grid.map((row)=>{return row.map((cell)=>{return cell.rootAvSet})}))
    
    setValues(currentValues);
    setCandidates(currentCandidates);
    setChangeCells(currentChangedCells);
    setTargetAvSet(currentTargetAvSet);
    setRootAvSet(currentRootAvSet);
  }
  return (
    <div className="App">
      <header className="App-headerh">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Solveku</title>
          <meta name="description" content="Sudoku solver application" />
          <link rel="canonical" href="http://solveku.com" />
        </Helmet>
        <div className='containerh' >
          <div className='menuh'>
            <button className={"solve-buttonh"} onClick={onSolve}>Solve</button>
            <div className={"titleh"}>
              Solveku
            </div>
              <button className={"reset-buttonh"} onClick={onReset}>Reset</button>
            
          </div>
          <div className='gridh'>
          <Grid 
            n={size} 
            values={values} 
            candidates={candidates}
            changedCells={changedCells}
            targetAvSet={targetAvSet}
            rootAvSet={rootAvSet} 
            changeValue={changeValue} 
            isGridEditable={isGridEditable}
            selectedIndex={selectedIndex}
            onCellClick={onCellClick}
            isMobile={true}></Grid>
            </div>
            {isGridEditable ?
          <NumberGrid n={size} isHorizontal={true} onInputClick={onInputClick}/> :
          <StageList nameList={stageNameList} gridList={stageGridList} onStageClick={onStageClick}/>
        }
        
          </div>
          
          <div>
            <div className='footer'>
              Created by Esteban Martínez and Andreas Wachtel.
            </div>
          </div>
          
      </header>
    </div>
  );
}

const toMatrix = (values, n)=>{
    const num_values = values.map((value) => {return value? parseInt(value) : 0});
    let res = [];
    for(let i=0; i < n; i++){
      res.push(num_values.slice(0 + i*n, (i+1)*n));
    }
    return res;
}

const onSolveClick = (matrix, setStageNameList, setStageGridList) => {
  const sudo = new SudokuGrid(matrix);
  try{
    sudo.solve();
    setStageNameList(sudo.stagesList);
    setStageGridList(sudo.allGrids);
  }
  catch(error){
     
  }
}

const toValueString = (valueNum) => {
  return valueNum ? valueNum.toString() : "";
}

export default HorizontalApp;