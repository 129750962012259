
import './NumberGrid.css';
export default function NumberGrid(props) {
    const {n, isHorizontal, onInputClick} =  props;
    
    
    const root = Math.floor(Math.sqrt(n));
    const values = Array.from({length: n}, (_, i) => i + 1);
    const valuesElements = values.map((value) => {
        const onClick = ()=>{
            onInputClick(value);
        }
        return <div key={value} className={"value"} onClick={onClick}>{value} </div>
    })
    const containerStyle = {
            gridTemplateRows: `repeat(${root}, auto)`,
             gridTemplateColumns: `repeat(${root}, auto)`,
            gridRow: isHorizontal ? "3" : undefined}
        
    
    return <div className={"numberContainer"} style={containerStyle}>
        {valuesElements}
    </div>
}