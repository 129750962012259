import "./StageList.css"
import {useState} from 'react'

export default function StageList(props){
    const {nameList, gridList, onStageClick} = props;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const nameListElements =  nameList ? nameList.map((name, index)=>{
        const onItemClick = () => {
            onStageClick(gridList[index]);
            setSelectedIndex(index);
        }
        return <div key={index} onClick={onItemClick}className="stage-item" style={selectedIndex ===index ? {backgroundColor: "lightblue"} : undefined}>{name}</div>
    }) : null;
    return <div className={"stage-list"}>
    {nameListElements}
</div>
}