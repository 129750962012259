import './CandidateCell.css';

export default function CandidateCell(props){
    const {n, candidateSet, rightMargin, bottomMargin, rootAvSet, targetAvSet, cellSize, isMobile} = props; 
    const root = Math.floor(Math.sqrt(n));
    const allCandidates = Array.from({length: n}, (_, i) => i + 1);
    const candidateSize = cellSize/root;
    const candidateElements = allCandidates.map((candidate) => {
        const candidateStyle = {
            width: candidateSize,
            height: candidateSize,
            visibility: candidateSet?.has(candidate) || targetAvSet?.has(candidate) ? undefined : "hidden",
            backgroundColor: rootAvSet?.has(candidate) ? "#647DB9" : targetAvSet?.has(candidate) ? "#C15F6D" : undefined,
            borderRadius: "3px",
            fontSize: isMobile ?"9px" : "14px"
    }
        return <div className={"candidate"} key={candidate} style={candidateStyle}>{candidate}</div>
    })
    const style = {
        height: `${cellSize}px`,
        width: `${cellSize}px`,
        gridTemplateRows: `repeat(${root}, ${candidateSize}px)`,
        gridTemplateColumns: `repeat(${root}, ${candidateSize}px)`,
        marginRight: rightMargin ? "2px" : undefined, 
        marginBottom: bottomMargin ? "2px": undefined,}
    return <div className="candidate-grid" style={style}>{candidateElements}</div>
}

 