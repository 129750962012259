import CandidateCell from "./CandidateCell"
import ValueCell from "./ValueCell"
import './Grid.css'
import React, { useState, useEffect} from "react";

const KEY_DOWN_LABEL = "keydown";
export default function Grid(props) {
    const {n, values, candidates, changeValue, isGridEditable, isMobile, selectedIndex, onCellClick, changedCells, rootAvSet, targetAvSet} = props;
    const [cellSize, setCellSize] = useState(36);
    const [gapSize, setGapSize] = useState(1);
    useEffect(()=>{
        if(isMobile){
            setCellSize(36);
            setGapSize(1);
        }
        else{
            setGapSize(2);
            setCellSize(60);
        }
    },[isMobile])
    const changeCellValue = (value) =>{
        changeValue(selectedIndex, value)
    }
    const keyPush = (key)=>{
        numberPush(key,changeCellValue, isGridEditable);
    }
    useEventNumberListener(keyPush);
    
    const indexes = Array.from(Array(n*n).keys());
    const utils = new Utils(n);
    const style = {
        gridTemplateRows: `repeat(${n}, auto)`, 
        gridTemplateColumns: `repeat(${n}, auto)`,
        gridGap: `${gapSize}px`}

    const valueCells = indexes.map(index => {
       return <ValueCell 
                key={index} 
                rightMargin={utils.hasRightMargin(index)}
                bottomMargin={utils.hasDownMargin(index)}
                index={index}
                onClick={onCellClick}
                backgroundColor={selectedIndex === index ? "#61dafb" : changedCells[index] ? "#647DB9" : undefined}
                value={values[index]}
                hasChanged={changedCells[index]}
                cellSize={cellSize}
                isMobile={isMobile}/>
    })
    const candidateCells = indexes.map(index => {
        return <CandidateCell
                 key={index}
                 n={n}
                 rightMargin={utils.hasRightMargin(index)}
                 bottomMargin={utils.hasDownMargin(index)}
                 candidateSet={candidates[index]}
                 rootAvSet={rootAvSet[index]}
                 targetAvSet={targetAvSet[index]}
                 cellSize={cellSize}
                 isMobile={isMobile} />
     })
     const showingCells = indexes.map(index =>{
        return  isGridEditable || values[index] !== "" ? valueCells[index] : candidateCells[index]
     })
     
    return <div className="grid" style={style}>{showingCells}</div>


}

class Utils{
    constructor(n){
    this.n = n;
    this.root = Math.floor(Math.sqrt(n));
    this.band = n*this.root;
    this.square = n*n;
    }
    hasRightMargin(index){
        return this.isAtBoxRightBorder(index) && !this.isAtGridRightBorder(index);
    }

    hasDownMargin(index){
        return this.isAtBoxDownBorder(index) && !this.isAtGridDownBorder(index);
    }
    isAtBoxRightBorder(index) {
        return index%this.root === this.root-1;
    }

    isAtGridRightBorder(index){
        return index%this.n === this.n-1;
    }

    isAtBoxDownBorder(index){
        return this.band-this.n <= index%this.band
    }   

    isAtGridDownBorder(index){
        return this.square-this.n <= index
    }
}

function numberPush(key, changeCellValue, isGridEditable){
    if(isGridEditable){
        const keyCode = key.keyCode;
        if((49 <= keyCode && keyCode <= 57) || keyCode === 32){
            changeCellValue(String.fromCharCode(keyCode));
        }
    }
  }

function useEventNumberListener(handler, element = document){
  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On 
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Add event listener
      element.addEventListener(KEY_DOWN_LABEL, handler);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(KEY_DOWN_LABEL, handler);
      };
    },
    [handler, element]
  );
};

